
import { defineComponent } from "vue";
import CodeHighlighter from "@/components/highlighters/CodeHighlighter.vue";
import { code5 } from "@/views/resources/documentation/element-ui/basic/button/code";

export default defineComponent({
  name: "button-group",
  components: {
    CodeHighlighter
  },
  setup() {
    return {
      code5
    };
  }
});
